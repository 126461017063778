<template>
  <div>
    <div class="content" :style="myStyleCat">
      <p></p>
      <p>
        <span class="ancho90" >
          <div>
          {{ informacionProducto }}
          </div>
          </span>
      </p>
    </div>
  </div>
</template>

<script>
import aws_service from "@/services/aws";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Descripcion",
  mounted() {
    this.DescargarInformacionProducto();
    this.myStyleCat= {
        "background-color": this.tienda.color2,
        "color": this.tienda.color3
        
      }
  },
  props: {
    categoria: String,
    producto: String
  },
  data: function() {
    return {
      show: true,
      informacionProducto: ""
    };
  },
  computed: {
    ...mapState(["transicion","tienda"])
  },
  methods: {
    ...mapMutations(["asignar_transicion"]),
    DescargarInformacionProducto() {
      var data = {
        tienda: this.tienda.Sk,
        producto: this.producto
      };
      aws_service
        .obtenerInformacionProducto(data)
        .then(response => {
          console.log(response);
          this.informacionProducto = response.data.Descripcion;
        })
        .catch(error => {
          console.log(error);
          alert("Error al buscar producto!");
        });
    }
  }
};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.content {
  text-align: justify;
  text-justify: inter-word;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
  padding-left: 10px;
}
.ancho90{
  
}
</style>
