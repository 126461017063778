<template>
  <div>
    <div class="espacio"  >
      <div
        ref="slider"
        v-hammer:swipe.horizontal="onSwipeLeft"
        class="card"
        @click= "onSwipeLeft(event)"
        :style="myStyleCat"
      >
        <div v-if="image != ''">
          <div class="card-image">
            <figure class="image is-4by3">
              <img :src="image" alt="Placeholder image" />
            </figure>
          </div>
          <div class="card-content">
            <div class="media">
              <div class="media-left">
                <div id="share-buttons">
                  <!-- Email -->
                <!--  <a
                    href="mailto:?Subject=Tienda&amp;Body=Te%20invito%20a%20conocer%20estos%20productos%20https://ztec.systems"
                  >
                    <img
                      src="https://simplesharebuttons.com/images/somacro/email.png"
                      alt="Email"
                    />
                  </a>-->
<!--<iframe src="https://www.facebook.com/plugins/share_button.php?href=https%3A%2F%2Fztec.systems&layout=button&size=small&width=89&height=20&appId" width="89" height="20" style="border:none;overflow:hidden" scrolling="no" frameborder="0" allowfullscreen="true" allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>-->
                  <!-- Facebook -->
<!--                  <a
                    href="http://www.facebook.com/sharer.php?u=ztec.systems"
                    target="_blank"
                  >
                    <img
                      src="https://simplesharebuttons.com/images/somacro/facebook.png"
                      alt="Facebook"
                    />
                  </a>-->
                </div>
              </div>
              <div class="media-content">
                <p class="title is-4" :style="myStyleCat">{{ nombre }}</p>
              </div>
            </div>
            <div class="content">
              
              {{descripcion}}
              
              <br />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import aws_service from "@/services/aws";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Categoria",
  mounted() {
    this.$refs.slider.hammer.set({
      touchAction: "pan-y"
    });
    this.myStyleCat= {
        "background-color": this.tienda.color2,
        "color": this.tienda.color3
        
      }
    this.DescargarImagenCategoria();
     
  },
  props: {
    ruta: String,
    categoria: String,
    nombre: String,
    extencion:String,
    descripcion:String,
       myStyleCat: {
        "background-color": "#ffffff"
      }
  },
  data: function() {
    return {
      show: true,
      image: require("@/assets/cargando.png")
    };
  },
  computed: {
    ...mapState(["transicion","tienda"])
  },
  methods: {
    ...mapMutations(["asignar_transicion"]),
    onSwipeLeft: function(e) {
      console.log("left", e);
       if (e.type == "swipeleft") {
      this.asignar_transicion("animated slideOutLeft");
      this.$router.push({
        name: "Productos",
        query: { categoria: this.categoria , tienda: this.$route.query.tienda }, 
        params: { categoria: this.categoria, tienda: this.$route.query.tienda }
      });
       }
    },
    
    DescargarImagenCategoria() {
      /*var data = {
        tienda: "69a32dcf-c7b5-48d5-818f-ea480bb909a2",
        categoria: this.categoria
      };*/
      this.image ='https://tiendas-ztec.s3-us-west-2.amazonaws.com/'+this.tienda.Sk+'/categorias/'+this.categoria+'.'+this.extencion;
      /*aws_service
        .obtenerImagenCategoria(data)
        .then(response => {
          console.log(response);
          this.image = "data:image/png;base64," + response.data;
        })
        .catch(error => {
          console.log(error);
          alert("Imagen no encontrada!");
        });*/
    }
  }
};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 10;
}
li {
  display: inline-block;
  margin: 10 10px;
}
a {
  color: #42b983;
}

#share-buttons img {
  width: 35px;
  padding: 5px;
  border: 0;
  box-shadow: 0;
  display: inline;
}
</style>
