<template>
  <div>
    <div >
    <div class="espacio">
      <div  ref="slider"
       v-hammer:swipe.horizontal="onSwipe"
        
       
        class="uk-card uk-card-default uk-card-body uk-width-1-2@m">

      <div
        class="card" :style="myStyleCat"
      >
        <div v-if="image1 != ''">
          <div v-if="muestraImagen" class="card-image">
            <figure class="image is-4by3">
              <img :src="imagenActual" alt="Placeholder image" style="width: auto;margin: auto;"/>
            </figure>
          </div>
          <div v-else class="card-image">
            <iframe width="100%" height="100%" :src="video">
            </iframe>
          </div>

          <div v-if="informacionProducto != null" class="card-content padding0" :style="myStyleCat">
              
            <div class="padding20">
              <div  class="inline" @click="MuestraImagen1" >
                  <img :src="image1"  width="48" height="48"  alt="Placeholder image" />
              </div>
              <div
                v-if="image2 != ''"
                @click="MuestraImagen2"
                class="inline"
              >
                  <img :src="image2"  width="48" height="48" alt="Placeholder image" />
              </div>
              <div
                v-if="image3 != ''"
                @click="MuestraImagen3"
                class="inline"
              >
                  <img :src="image3" width="48" height="48" alt="Placeholder image" />
              </div>
              <div v-if="image4 != ''" @click="MuestraVideo" class="inline">
                  <img :src="image4" width="48" height="48" alt="Placeholder image" />
              </div>

              <b-field class="vertical-center inline" >
                <b>${{ informacionProducto.precioCadena}}</b>
              </b-field>
            </div>
            
            <div class="content">
              <b>{{ informacionProducto.Nombre }}</b>

              <br />
            </div>
                <div class="inline0">
                <b-field class=" inline">
                  {{ informacionProducto.Subcategoria }} <span v-if=' informacionProducto.Subcategoria!=""'>:</span>
                </b-field>
                <b-button class="uk-button"
                  rounded
                  v-for="(subcategoria,
                  index) in informacionProducto.Detalle_Subcategoria"
                  :class="{ 'is-focused': index === 0 && !seleccion }"
                  :key="subcategoria"
                  @click="SeleccionaSubCategoria(subcategoria)"
                >
                  <span
                    v-if="
                      subcategorias.find(
                        sub => sub.subcategoria == subcategoria
                      )
                    "
                    title="Badge top right"
                    class="badge"
                    :style="myStyle"
                    >{{
                      subcategorias.find(
                        sub => sub.subcategoria == subcategoria
                      ).contador
                    }}</span
                  >
                  {{ subcategoria }}
                </b-button>
                </div>
              <b-field class="padding20">
                <b-numberinput
                  v-model="number"
                  type="is-light"
                  :min="0"
                ></b-numberinput>
              </b-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import aws_service from "@/services/aws";
import "@creativebulma/bulma-badge/dist/bulma-badge.min.css";
export default {
  name: "Producto",
  mounted() {
    this.$refs.slider.hammer.set({
      touchAction: "pan-y",
      
    });
    this.cargando = false;
    this.myStyleCat= {
        "background-color": this.tienda.color2,
        "color": this.tienda.color3
        
      }
    this.DescargarInformacionProducto();
    this.DescargarImagenProducto();
  },
  props: {
    producto: String,
    categoria: String,
  },
  data: function() {
    return {
      show: true,
      imagenActual: require("@/assets/cargando.png"),
      image1: require("@/assets/cargando.png"),
      image2: require("@/assets/cargando.png"),
      image3: require("@/assets/cargando.png"),
      image4: require("@/assets/cargando.png"),
      number: 0,
      subSeleccionada: "",
      subcategorias: [],
      informacionProducto: null,
      muestraImagen: true,
      cargando: false,
      seleccion: false,
      extencion1: "",
      extencion2: "",
      extencion3: "",
      extencion4: "",
      video:"",
      activo:true,
          
      myStyle: {
        "--color-primario": "#F00"
      }
    };
  },
  watch: {
    // whenever question changes, this function will run
    number: function(newNumber, oldNumber) {
      if (!this.cargando) {
        var foundIndex = this.subcategorias.findIndex(
          sub => sub.subcategoria == this.subSeleccionada
        );
        if (foundIndex >= 0) {
          this.subcategorias[foundIndex] = {
            subcategoria: this.subSeleccionada,
            contador:
              this.subcategorias[foundIndex].contador + newNumber - oldNumber
          };
        } else {
          this.subcategorias.push({
            subcategoria: this.subSeleccionada,
            contador: 1
          });
        }
        var productoModificado = {
          precio: +this.informacionProducto.Precio,
          id: this.informacionProducto.Sk.substring(10),
          nombre: this.informacionProducto.Nombre,
          cantidad: newNumber,
          subcategorias: this.subcategorias
        };
        this.modifica_carrito(productoModificado);
        console.log(this.subcategorias);
      } else {
        this.cargando = false;
      }
    }
  },
  computed: {
    ...mapState(["transicion", "carrito","tienda"])
  },
  methods: {
    ...mapMutations(["asignar_transicion", "modifica_carrito"]),
    onSwipe(event) {
      console.log(event.type);

      if (event.type == "swipeleft") {
        this.asignar_transicion("animated slideOutLeft");
        this.$router.push({
          name: "Detalle",
          query: { producto: this.producto, categoria: this.categoria, tienda: this.$route.query.tienda }
        });
      } /* else {
        this.asignar_transicion("animated slideOutRight")  
      this.$router.push({ name: 'Home'});
      }*/
    },
    DescargarInformacionProducto() {
      var data = {
        tienda: this.tienda.Sk,
        producto: this.producto
      };
      aws_service
        .obtenerInformacionProducto(data)
        .then(response => {
          console.log(response);
          this.informacionProducto = response.data;
          const options = { 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
          };
          this.activo= (this.informacionProducto.estado== true || this.informacionProducto.estado=='Activo' )&& (this.informacionProducto.activo===undefined || this.informacionProducto.activo==true  )
          this.informacionProducto.precioCadena=Number(+this.informacionProducto.Precio).toLocaleString('en', options);
          console.log(this.informacionProducto);
          this.extencion1 = this.informacionProducto.Extencion1;
          this.extencion2 = this.informacionProducto.Extencion2;
          this.extencion3 = this.informacionProducto.Extencion3;
          this.extencion4 = this.informacionProducto.Extencion4;
          var finVideo=this.informacionProducto.Ruta4.indexOf("&")
          if(finVideo<=0)
          {
            finVideo=this.informacionProducto.Ruta4.length
          }
          this.video= "https://www.youtube.com/embed/"+ this.informacionProducto.Ruta4.substring(this.informacionProducto.Ruta4.indexOf("v=")+2,finVideo)
          this.DescargarImagenProducto();
          if (this.informacionProducto.Detalle_Subcategoria.length > 0) {
            this.subSeleccionada = this.informacionProducto.Detalle_Subcategoria[0];
          }
          console.log(this.informacionProducto.Detalle_Subcategoria);
          this.informacionProducto.Detalle_Subcategoria.forEach(sub => {
            var foundIndex = this.carrito.findIndex(
              articulo => articulo.id === this.producto
            );
            var primerSubcategoria = true;
            if (foundIndex >= 0) {
              this.carrito[foundIndex].subcategorias.forEach(subCarrito => {
                if (subCarrito.subcategoria == sub) {
                  if (primerSubcategoria) {
                    this.number = subCarrito.contador;
                    if (this.number > 0) {
                      this.cargando = true;
                    }
                  }

                  var index = this.subcategorias.findIndex(
                    subcat => subcat.subcategoria == sub
                  );
                  if (index >= 0) {
                    this.subcategorias[index] = {
                      subcategoria: sub,
                      contador: subCarrito.contador
                    };
                  } else {
                    this.subcategorias.push({
                      subcategoria: sub,
                      contador: subCarrito.contador
                    });
                  }
                }
                primerSubcategoria = false;
              }, this);
            }
          }, this);
        })
        .catch(error => {
          console.log(error);
//          alert("Error al buscar producto!");
        });
    },
    DescargarImagenProducto() {
      if (this.extencion1 != "" )
        this.image1 ='https://tiendas-ztec.s3-us-west-2.amazonaws.com/'+this.tienda.Sk+'/productos/'+this.producto+'%2301.'+this.extencion1;
      if (this.extencion2 != "" )
      {
        this.image2 ='https://tiendas-ztec.s3-us-west-2.amazonaws.com/'+this.tienda.Sk+'/productos/'+this.producto+'%2302.'+this.extencion2;
      }else
      {
        this.image2='';
      }
      if (this.extencion3 != "")
      {
        this.image3 ='https://tiendas-ztec.s3-us-west-2.amazonaws.com/'+this.tienda.Sk+'/productos/'+this.producto+'%2303.'+this.extencion3;
      }else
      {
        this.image3='';
      }
      if (this.extencion4 != "")
      {
        this.image4 ='https://tiendas-ztec.s3-us-west-2.amazonaws.com/'+this.tienda.Sk+'/productos/'+this.producto+'%2304.'+this.extencion4;
      }else
      {
        this.image4='';
      }
      this.imagenActual = this.image1;
      /*var data = {
        tienda: "69a32dcf-c7b5-48d5-818f-ea480bb909a2",
        producto: this.producto,
        imagen: "1"
      };
      aws_service
        .obtenerImagenProducto(data)
        .then(response => {
          console.log(response);
          this.image1 = "data:image/png;base64," + response.data;
          this.imagenActual = this.image1;
        })
        .catch(error => {
          console.log(error);
          alert("Imagen no encontrada!");
        });
      data = {
        tienda: "69a32dcf-c7b5-48d5-818f-ea480bb909a2",
        producto: this.producto,
        imagen: "2"
      };
      aws_service
        .obtenerImagenProducto(data)
        .then(response => {
          console.log(response);
          this.image2 = "data:image/png;base64," + response.data;
        })
        .catch(error => {
          console.log(error);
          alert("Imagen no encontrada!");
        });

      data = {
        tienda: "69a32dcf-c7b5-48d5-818f-ea480bb909a2",
        producto: this.producto,
        imagen: "3"
      };
      aws_service
        .obtenerImagenProducto(data)
        .then(response => {
          console.log(response);
          this.image3 = "data:image/png;base64," + response.data;
        })
        .catch(error => {
          console.log(error);
          alert("Imagen no encontrada!");
        });
      data = {
        tienda: "69a32dcf-c7b5-48d5-818f-ea480bb909a2",
        producto: this.producto,
        imagen: "4"
      };
      aws_service
        .obtenerImagenProducto(data)
        .then(response => {
          console.log(response);
          this.image4 = "data:image/png;base64," + response.data;
        })
        .catch(error => {
          console.log(error);
          alert("Imagen no encontrada!");
        });*/
    },
    MuestraVideo() {
      this.muestraImagen = false;
    },
    MuestraImagen1() {
      this.MuestraImagen(this.image1);
    },
    MuestraImagen2() {
      this.MuestraImagen(this.image2);
    },
    MuestraImagen3() {
      this.MuestraImagen(this.image3);
    },
    MuestraImagen4() {
      this.MuestraImagen(this.image4);
    },
    MuestraImagen(img) {
      this.muestraImagen = true;
      this.imagenActual = img;
    },
    SeleccionaSubCategoria(subcategoria) {
      if (this.subSeleccionada != subcategoria) {
        this.seleccion = true;
        this.subSeleccionada = subcategoria;
        this.cargando = true;

        var foundIndex = this.subcategorias.findIndex(
          sub => sub.subcategoria == this.subSeleccionada
        );
        if (foundIndex >= 0) {
          this.number = this.subcategorias[foundIndex].contador;
        } else {
          this.number = 0;
        }
      }
    }
  }
};
</script>
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 10;
}
li {
  display: inline-block;
  margin: 10 10px;
}
a {
  color: #42b983;
}
b-button {
  display: relative;
}
.badge {
  background-color: var(--color-primario);
}
.inline{
  display:inline;
  padding:1px;
}
.padding0{
  padding:0;
}
.padding20{
  padding:0;
  padding-top:20px;
  padding-bottom:20px;
}
.number-button {
  background-color: #0f0;
}
img
{
  min-height:48px;
}
</style>
