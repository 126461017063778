<template>
  <div class="productos">
    <div
      ref="slider"
       v-hammer:swipe.horizontal="onSwipe"
    
    >
      <!--    <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link to="/">
          <h1>Tu tienda en línea</h1>
        </router-link>

        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
    </nav>-->
    <div v-if='vacio'>
      <div class="sinProductos" >Sin productos aún</div>
    </div>
    <div>
      <ul v-if="catalogoProductos">
        <li v-for="producto in catalogoProductos" :key="producto.Sk">
          <div v-if="(producto.Estado===undefined && producto.Activo===undefined)||(producto.Estado==='Activo' && producto.Activo===true)">
          <Producto
            :producto="producto.Sk.substring(10)"
            :categoria="categoria"
          />
          </div>
        </li>
      </ul>
    </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Producto from "@/components/Producto.vue";
import aws_service from "@/services/aws";
import { mapState, mapMutations } from "vuex";
export default {
  name: "Productos",
  components: {
    Producto
  },
  mounted() {
    this.$refs.slider.hammer.set({
      touchAction: "pan-y"
    });
    this.categoria = this.$route.query.categoria;
    this.DescargarCatalogoProductos();
  },
  props: {},
  data: function() {
    return {
      show: true,
      catalogoProductos: [],
      categoria: String,
      vacio:false,
    };
  },
   computed: {
    ...mapState(["tienda"])
  },
  methods: {
    ...mapMutations(["asignar_transicion"]),
    onSwipe(event) {
      console.log(event.type);
      console.log("***********************************************")
      if (event.type == "swiperight") {
        this.asignar_transicion("animated slideOutRight");
        this.$router.push({
          name: "Home",
          query: { tienda: this.$route.query.tienda }
        });
        
      }
      /*else if (event.type == "swipeleft") {
       this.asignar_transicion("animated slideOutLeft");
        this.$router.push({
          name: "Detalle",
          query: { producto: this.producto, categoria: this.categoria }
        });
      }*/
    },
    DescargarCatalogoProductos() {
      //var data= {tienda:'69a32dcf-c7b5-48d5-818f-ea480bb909a2',categoria:this.categoria};
      var data = {
        tienda: this.tienda.Sk,
        categoria: this.categoria
      };
      this.vacio=false;
      aws_service
        .obtenerCatalogoProductos(data)
        .then(response => {
          console.log(response);
          var eliminados= response.data.filter(x=>!((x.Estado===undefined && x.Activo===undefined)||(x.Estado==='Activo' && x.Activo===true))).length
          if (response.data.length==0 || eliminados==response.data.length)
          {
           this.vacio=true; 
          }else{
          this.catalogoProductos = response.data;
          }
        })
        .catch(error => {
          console.log(error);
          alert("Error al buscar productos!");
          this.vacio= true;
        });
    }
  }
};
</script>

<style>
.sinProductos{
  height:100vh;
 
}
</style>

