import Vue from "vue";
import Vuex from "vuex";

import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
var ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    transicion: "animated slideOutLeft",
    carrito: [],
    totalPrecio: 0,
    totalProductos: 0,
    totalPrecioCadena:"",
    mensaje: "",
    lista:[],
    tienda:null
  },
  mutations: {
    asignar_transicion: (state, transicion) => {
      state.transicion = transicion;
    },
    asignar_tienda: (state, tienda) => {
      state.tienda = tienda;
    },
    borra_carrito:(state)=>{
      state.carrito= [];
      state.totalPrecio= 0;
      state.totalProductos= 0;
      state.mensaje= "";
    },
    modifica_carrito: (state, producto) => {
      var foundIndex = state.carrito.findIndex(
        articulo => articulo.id === producto.id
      );

      if (foundIndex >= 0) {
        state.carrito[foundIndex] = producto;
      } else {
        state.carrito.push(producto);
      }
      state.totalPrecio = 0;
      state.totalProductos = 0;
      const options = { 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
          };
      state.lista=[];
      var listaSubcategorias=[];
      state.mensaje = "Hola, me interesan los siguientes productos:%0A%0A";
      state.carrito.forEach(element => {
        element.cantidad = 0;
        var subcategorias = "";
        console.log(element.subcategorias);
        element.subcategorias.forEach(sub => {
          if(sub.subcategoria!="")
          {
          subcategorias +=
            "%20%20%20%20" + sub.contador + "%20%20" + sub.subcategoria + "%0A";
          listaSubcategorias.push({cantidad:sub.contador, subcategoria:sub.subcategoria})
          }
          element.cantidad += sub.contador;
          
        });

        state.totalPrecio += +element.precio * element.cantidad;
        state.totalProductos += element.cantidad;
        state.mensaje +=
          element.cantidad +
          "%20%20" +
          element.nombre +
          "%20%20($" 
          +(Number(element.precio * element.cantidad).toLocaleString('en', options)) +
          ")%0A" +
          subcategorias;
          state.lista.push({cantidad:element.cantidad,producto:element.nombre, precioUnitario:element.precio,subcategorias:listaSubcategorias})
      });
      state.totalPrecioCadena=Number(state.totalPrecio).toLocaleString('en', options);
      state.mensaje +=
        "-------------------------------%0APor un total de $" +
        state.totalPrecioCadena;
        
        console.log(state.mensaje)
        
    }
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ]
});
