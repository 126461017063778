import Axios from "axios";
import store from "../store";

const RESOURCE_STORE =
  "https://dyr7cj5zoc.execute-api.us-west-2.amazonaws.com/api";
const RESOURCE_STORE_FILES =
  "https://xvim4wxi0f.execute-api.us-west-2.amazonaws.com/api";
//const RESOURCE_STORE = 'http://54.203.155.133:8001';
//const RESOURCE_STORE_FILES= 'http://54.185.212.199:8000';

Axios.interceptors.request.use(
  function(config) {
    const auth_token = store.getters.token;
    if (auth_token != "") {
      config.headers.Authorization = `Bearer ${auth_token}`;
    }
    return config;
  },
  function(err) {
    return Promise.reject(err);
  }
);

export default {
  login(datos) {
    console.log(datos);
    //return Axios.post( `${RESOURCE_SECURITY}/login/`,datos)
  },
  guardarImagenCategoria(tienda, categoria, img) {
    let formData = new FormData();
    formData.append("img", img);
    formData.append("tienda", tienda);
    formData.append("categoria", categoria);

    return Axios.post(`${RESOURCE_STORE_FILES}/cargar_categoria`, formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    });
  },
  obtenerImagenCategoria(data) {
    return Axios.post(`${RESOURCE_STORE_FILES}/descargar_categoria`, data);
  },
  obtenerImagenProducto(data) {
    return Axios.post(`${RESOURCE_STORE_FILES}/descargar_producto`, data);
  },
  guardarCategoria(data) {
    return Axios.post(`${RESOURCE_STORE}/guardar_categoria`, data);
  },
  obtenerCategorias(data) {
    return Axios.post(`${RESOURCE_STORE}/obtener_categorias`, data);
  },
  obtenerCatalogoProductos(data) {
    return Axios.post(`${RESOURCE_STORE}/obtener_catalogo_productos`, data);
  },
  obtenerTienda(data) {
    return Axios.post(`${RESOURCE_STORE}/obtener_tienda`, data);
  },
  obtenerInformacionTienda(data) {
    return Axios.post(`${RESOURCE_STORE}/obtener_informacion_tienda`, data);
  },
  obtenerInformacionProducto(data) {
    return Axios.post(`${RESOURCE_STORE}/obtener_informacion_producto`, data);
  },
  solicitar(data){
    return Axios.post(`${RESOURCE_STORE}/registrar_solicitud`, data);
  }
  
};
