<template>
  <div id="app" :style="myStyle">
    <transition
      appear
      enter-active-class="animated zoomInUp delay"
      :leave-active-class="transicion"
    >
      <router-view></router-view>
    </transition>
     <b-modal v-model="isCardModalActive" :width="640" scroll="keep">
       
            <div class="card">
               <div class="card-content">
              Ordena por:
              
            <button class="button" @click="OrdenaWhatsapp" style="border-color:white">
              <span class="icon is-small">
                <b-icon
                          icon="whatsapp"
                          size="is-large"
                          type="is-success"
                          >
                      </b-icon>
              </span>
            </button>
            <hr>
               
               <div class="columns1">
                 <div v-for="producto in lista" key="producto">
                  <div class="column1">
                    <p>
                    {{producto.producto}}
                    </p>
                    <p>
                    {{producto.cantidad}} X  {{Number(producto.precioUnitario).toLocaleString('en', options)}} = {{Number(producto.cantidad*producto.precioUnitario).toLocaleString('en', options)}}
                    </p>
                    <hr>
                  </div>
                  </div>
                </div>
                Ordena por:
              <button class="button" @click="OrdenaWhatsapp" style="border-color:white">
              <span class="icon is-small">
                <b-icon
                          icon="whatsapp"
                          size="is-large"
                          type="is-success"
                          >
                      </b-icon>
              </span>
            </button>
            </div>
            </div>
        </b-modal>
    <b-navbar v-if="totalProductos>0" fixed-bottom centered :mobile-burger="muestraMenu" :style="myStyle">
      
      <template #brand>
      
        <b-navbar-item @click="BorraCarrito" >
          <b-icon icon="delete" size="is-large"  :style="myStyle"> </b-icon>
        </b-navbar-item>
        <b-navbar-item :style="myStyle">
          {{ totalProductos }} Artículos (${{ totalPrecioCadena }})
        </b-navbar-item>
        <b-navbar-item @click="MandarMensaje">
          <!--<a
            :href="'https://api.whatsapp.com/send?phone='+telefono+'&text=' + mensaje"
          >-->
            <b-icon icon="cart" size="is-large" :style="myStyle" > </b-icon>
          <!--</a>-->
        </b-navbar-item>
       
      </template>
      <template #start>
            <b-navbar-item href="#">
                Pedir por:
            </b-navbar-item>
            <b-navbar-item href="#">
               <b-icon
                icon="whatsapp"
                size="is-large"
                type="is-success">
            </b-icon>
                
            </b-navbar-item>
            
        </template>
    </b-navbar>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import aws_service from "@/services/aws";
import Carrito from "@/components/Carrito.vue"
export default {
  name: "App",
  components: {
    Carrito
  },
  mounted() {
    console.log("develop");
    if(this.$route.query.tienda){
    this.ObtenTienda();      
    }
    else
    {
      var url = window.location.href;    
      if (url.indexOf('?') > -1){
         url += '&tienda=alebrijes'
      }else{
         url += '?tienda=alebrijes'
      }
      window.location.href = url;
    }

  },
  data() {
    return {
      enterClass: "animated zoomInUp delay",
      muestraMenu: false,
      telefono: "",
       isCardModalActive: false,
     
       myStyle: {
        "background-color": "#ffffff"
      },
      options :{ 
            minimumFractionDigits: 2, 
            maximumFractionDigits: 2 
          }
    };
  },
  computed: {
    ...mapState(["transicion", "totalPrecio","totalPrecioCadena", "totalProductos", "mensaje","lista","tienda"])
  },
  methods: {
    ...mapMutations(["asignar_transicion","asignar_tienda","borra_carrito"]),
    BorraCarrito(){
      this.borra_carrito()
    },
    MandarMensaje()
    {
      console.log(this.lista)
      this.isCardModalActive = true;
      //this.Solicitar();
      //window.open('https://api.whatsapp.com/send?phone='+this.telefono+'&text=' + this.mensaje,"_blank");
    },
    OrdenaWhatsapp(){
      this.Solicitar();
      window.open('https://api.whatsapp.com/send?phone='+this.telefono+'&text=' + this.mensaje,"_blank");
    },
    Solicitar()
    {
       var data = { tienda: this.tienda.Sk.substring(this.tienda.Sk.indexOf('#')+1), telefono :this.telefono, productos:this.lista,total:this.totalPrecio};
      aws_service
        .solicitar(data)
        .then(response => {
          console.log(response)
        }).catch(error => {
          console.log(error);
          alert("Solicitud no enviada!");
        });
    },
    ObtenTienda(){
    
     var data = { nombre: this.$route.query.tienda };
      aws_service
        .obtenerTienda(data)
        .then(response => {
          console.log(response);
           this.myStyle= {
           "background-color": response.data.color1,
           "color": response.data.color4
           
           }
           document.body.style.backgroundColor = response.data.color1;
            document.querySelector('html').setAttribute('style', 'background:'+response.data.color1)
          var tienda=response.data
          tienda.Sk=tienda.Sk.substring(tienda.Sk.indexOf('#')+1)
          this.asignar_tienda(tienda)
          if(this.$route.query.contacto && tienda.Telefonos && tienda.Telefonos.filter(x=>x==this.$route.query.contacto).length>0) 
            {
              this.telefono= this.$route.query.contacto;
            }else
            {
              this.telefono= tienda.Telefono;
            }
        })
        .catch(error => {
          console.log(error);
          alert("Tienda no encontrada!");
        });
     
      }
    
  }
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
.delay {
  animation-delay: 0.6s;
}

.vertical-center {
  padding: 5px;
}

.espacio {
  padding: 10px;
}
img {
  border-radius: 7%;
}
</style>
