<template>
  <div class="categorias">
    <ul id="categorias">
      <li v-for="categoria in categorias" :key="categoria.id">
        <div v-if="(categoria.estado=='Activo' || categoria.estado==true) && categoria.eliminado==false">
        <Categoria
          ruta="productos"
          :nombre="categoria.nombre"
          :categoria="categoria.id"
          :extencion="categoria.extencion"
          :descripcion="categoria.descripcion"
        />
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import aws_service from "@/services/aws";
// @ is an alias to /src
import Categoria from "@/components/Categoria.vue";
import { mapState } from "vuex";
export default {
  name: "Home",
  mounted() {
    console.log("dev");
    this.DescargarCategorias();
    
      },
  components: {
    Categoria
  },
  data() {
    return {
      categorias: []
    };
  },
   computed: {
    ...mapState(["tienda"])
  },
  methods: {
    DescargarCategorias() {
      
        var data1 = { nombre: this.$route.query.tienda };
      aws_service
        .obtenerTienda(data1)
        .then(response => {
          
          var tienda=response.data.Sk.substring(response.data.Sk.indexOf('#')+1)
          var data = { tienda: tienda};
      aws_service
        .obtenerCategorias(data)
        .then(response => {
          console.log(response);
          response.data.forEach(cat => {
            console.log(cat);
            var eliminado= false;
            if(cat.Activo!=undefined)
            {
              eliminado= !cat.Activo;
            }
            this.categorias.push({
              id: cat.Sk.substring(11),
              nombre: cat.Nombre,
              extencion:cat.Extencion,
              descripcion:cat.Descripcion,
              estado:cat.Estado,
              eliminado:eliminado
            });
          });
          console.log(this.categorias);
        })
        .catch(error => {
          console.log(error);
          alert("Categoria no guardado!");
        });
        })
        .catch(error => {
          console.log(error);
          alert("Tienda no encontrada!");
        });
      
      
      
      
      
      
      
      
      /*aws_service
        .obtenerInformacionTienda(data)
        .then(response => {
          console.log(response);
        })
        .catch(error => {
          console.log(error);
       //   alert("Categoria no guardado!");
        });*/
    }
  }
};
</script>
