<template>
  <div class="descripcion">
    <div ref="slider" 
    v-hammer:swipe.horizontal="onSwipe"
    >
      <!--  <nav class="navbar" role="navigation" aria-label="main navigation">
      <div class="navbar-brand">
        <router-link to="/productos">
          <img
            src="https://bulma.io/images/bulma-logo.png"
            alt="Bulma: Free, open source, and modern CSS framework based on Flexbox"
            width="112"
            height="28"
          />
        </router-link>

        <a
          role="button"
          class="navbar-burger"
          aria-label="menu"
          aria-expanded="false"
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
    </nav>-->
      <ul>
        <li>
          <Producto
            :producto="$route.query.producto"
            :categoria="$route.query.categoria"
            :tienda="$route.query.tienda"
          ></Producto>
          <Descripcion
            :producto="$route.query.producto"
            :categoria="$route.query.categoria"
            :tienda="$route.query.tienda"
          ></Descripcion>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Producto from "@/components/Producto.vue";
import Descripcion from "@/components/Descripcion.vue";
import { mapMutations } from "vuex";
export default {
  name: "Detalle",
  components: {
    Producto,
    Descripcion
  },
  mounted() {
    this.$refs.slider.hammer.set({
      touchAction: "pan-y"
    });
    this.producto = this.$route.query.producto;
    this.categoria = this.$route.query.categoria;
     
  },
  data: function() {
    return {
      producto: String,
      categoria: String,
      informacionProducto: String
    };
  },
   
   
  props: {},
  methods: {
    ...mapMutations(["asignar_transicion"]),
    onSwipe: function(e) {
      console.log("left", e);
      if (e.type == "swiperight") {
      this.asignar_transicion("animated slideOutRight");
      this.$router.push({
        name: "Productos",
        query: { producto: this.producto, categoria: this.categoria, tienda: this.$route.query.tienda  }
      });
    }
  }
  }
};
</script>
